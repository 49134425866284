:root {
  /* --primary-color: #4948bc; */
  /* --primary-color: #449373; */
  --primary-color: #e1bf34;
  --primary-bg: #181818;
  /* --primary-color: purple; */
}

li {
  font-size: 1.5rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-bg);
  color: white;
}

* {
  scroll-behavior: smooth !important;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-glow-highlight {
  animation: shine 3s infinite;
  animation-direction: alternate;
}

@keyframes shine {
  from {
  }
  to {
    color: var(--primary-color);
    font-weight: bold;
  }
}
